<template>
  <b-card-actions
    ref="UsersList"
    title="Whatsapp"
    class="top-level"
    action-refresh
    @refresh="updateRows({})"
  >

    <b-row class="mb-1 mb-md-0">
      <b-col
        cols="12"
        md="6"
      >
        <b>{{ $store.state.whatsapp.messageTemplates.length }}</b> de <b>{{ $store.state.whatsapp.messageTemplates.length }}</b> templates whatsapp
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mb-1"
      >
        <b-button
          class="float-right"
          variant="success"
          :disabled="state === 'loading'"
          @click="createTemplate()"
        >
          Novo template
        </b-button>
      </b-col>
    </b-row>
    <div>
      <b-card
        v-for="template in rows"
        :key="template.id"
        :class="`mb-1 list-row ${selected.includes(template.id) ? 'row-selected' : ''}`"
        @click="editTemplate(template.id)"
      >

        {{ template.name }}
      </b-card>
    </div>
    <b-modal
      id="modal-template"
      v-model="showDelete"
      :title="titleEdit"
    >
      Você tem certeza que deseja excluir o template {{ titleEdit }}?
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="deleteTemplate()"
          >
            Deletar
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            class="float-right"
            @click="showDelete=false"
          >
            Fechar
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-template"
      v-model="showModal"
      :title="titleEdit"
    >
      <b-form-input
        v-model="titleEdit"
        :disabled="state === 'loading'"
        placeholder="Nome do template"
        type="text"
        class="d-inline-block"
        autocomplete="off"
      />
      <b-form-textarea
        v-model="templateContent"
        :disabled="state === 'loading'"
        placeholder="Mensagem"
        type="text"
        class="d-inline-block"
        autocomplete="off"
        rows="5"
        max-rows="10"
      />

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="saveTemplate()"
          >
            Gravar
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            class="float-right"
            @click="showModal=false"
          >
            Fechar
          </b-button>
          <b-button
            v-if="templateId"
            @click="showModal=false; showDelete=true"
          >
            Deletar
          </b-button>
        </div>
      </template>
    </b-modal>

  </b-card-actions>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBPopover, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BForm,
  BFormCheckbox, BFormDatepicker, BModal, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import WhatsappMenu from '@/components/WhatsappMenu.vue'
import { t } from '@/@core/libs/i18n/utils'

export default {
  components: {
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
    BForm,
    BButton,
    BCardActions,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    WhatsappMenu,
    BModal,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      allSelected: false,
      selected: [],
      searchFilter: null,
      state: 'ready',
      dir: false,
      allSelectState: false,
      showModal: false,
      templateId: null,
      templateContent: null,
      titleEdit: null,
      showDelete: false,
    }
  },
  computed: {
    rows() {
      return this.$store.state.whatsapp.messageTemplates
    },
  },
  watch: {
    allSelected(value) {
      if (value) {
        this.selected = this.$store.state.orders.ordersList.map(item => item.id)
        this.allSelectState = false
      } else if (this.allSelectState) {
        this.allSelectState = false
      } else {
        this.selected = []
      }
    },
    selected(value) {
      if (value.length !== this.$store.state.orders.ordersList.length) {
        this.allSelectState = true
        this.allSelected = false
      }
    },
  },
  created() {
    this.dateFormater = new Intl.DateTimeFormat('pt-br', {
      hour12: false,
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      // timeZoneName: 'short',
      // hour: 'numeric',
      // minute: '2-digit',
      timeZone: 'America/Sao_Paulo',
    })
    if (this.$store.state.users.usersList.length === 0) {
      this.pesquisa()
    }
    this.searchFilter = this.$store.state.users.filters.search ? this.$store.state.users.filters.search : ''
    if (this.$store.state.whatsapp.messageTemplates.length === 0) {
      this.$store.dispatch('whatsapp/fetchMessageTemplates')
    }
  },
  methods: {
    createTemplate() {
      this.templateContent = null
      this.titleEdit = null
      this.templateId = null
      this.showModal = true
    },
    editTemplate(templateId) {
      const template = this.$store.state.whatsapp.messageTemplates.find(template => template.id === templateId)
      this.templateContent = template.template
      this.titleEdit = template.name
      this.templateId = templateId
      this.showModal = true
    },
    selectCard(id, unique = true) {
      const index = this.selected.indexOf(id)
      if (unique) {
        if (index > -1) {
          this.selected = []
        } else {
          this.selected = [id]
        }
      } else if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(id)
      }
    },
    pesquisa() {
      this.state = 'loading'
      try {
        this.$refs.UsersList.showLoading = true
      } catch (error) {

      }
      this.$store.commit('users/firstPage')
      this.$store.dispatch('users/getUsers', {}).finally(() => {
        this.selected = this.selected.filter(item => this.$store.state.users.UsersList.find(order => order.id === item))
        this.state = 'ready'
        try {
          this.$refs.UsersList.showLoading = false
        } catch (error) {

        }
      })
    },
    updateRows(filters) {
      this.state = 'loading'
      try {
        this.$refs.UsersList.showLoading = true
      } catch (error) {

      }
      this.$store.dispatch('users/getUsers', filters).finally(() => {
        this.selected = this.selected.filter(item => this.$store.state.orders.ordersList.find(order => order.id === item))
        this.state = 'ready'
        try {
          this.$refs.UsersList.showLoading = false
        } catch (error) {

        }
      })
    },
    getUniqueElements(arr, keyName) {
      return [...new Map(arr.map(item => [item[keyName], item])).values()]
    },
    saveTemplate() {
      if (!this.templateId) {
        this.$store.dispatch('whatsapp/createItem', {
          template: this.templateContent,
          name: this.titleEdit,
        })
        this.templateContent = null
        this.titleEdit = null
        this.templateId = null
        this.showModal = false
        return
      }
      this.$store.dispatch('whatsapp/updateItem', {
        id: this.templateId,
        template: this.templateContent,
        name: this.titleEdit,
      })
      this.templateContent = null
      this.titleEdit = null
      this.templateId = null
      this.showModal = false
    },
    deleteTemplate() {
      this.$store.dispatch('whatsapp/deleteItem', this.templateId)
      this.templateContent = null
      this.titleEdit = null
      this.templateId = null
      this.showModal = false
      this.showDelete = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.card-body {
    padding-top: 0;
    padding-bottom: 0;
}

.list-row:hover {
    background: rgb(226, 226, 226);
    color: inherit;
}

.row-selected {
    background: #004259;
    color: white !important;
}

.row-selected:hover {
    background: #004159cc;
    color: white;
}

.flex-parent {
    display: flex;
    align-items: center;
    padding: 10px;
    // margin: 1em 0;
}

.long-and-truncated {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#filter_status {
    border-radius: 6px;
}
</style>
